const getWindowWidth = () => {
    addMobileRewards(document.documentElement.clientWidth);
}

const addMobileRewards = (screenWidth) => {
    if(screenWidth < 768) {
        const rewards = document.querySelector('.nfl-club-hero__rewards');
        if(rewards) {
            const newClone = createClone(rewards, 'nfl-club-hero__rewards', 'nfl-club-hero__rewards-mobile__container');
            const mobileRewardBlock = document.querySelector('.nfl-club-hero__rewards-mobile .cmp-container .aem-Grid').prepend(newClone); 
            rewards.remove();
            if(mobileRewardBlock) {
                mobileRewardBlock.prepend(newClone);
            }
        }
    }
    else {
        const rewardsMobile = document.querySelector('.nfl-club-hero__rewards-mobile__container');
        if(rewardsMobile){
            const newClone = createClone(rewardsMobile, 'nfl-club-hero__rewards-mobile__container', 'nfl-club-hero__rewards');
            const nflClubTitle = document.querySelector('.nfl-club-hero--title');
            nflClubTitle.parentNode.insertBefore(newClone, nflClubTitle.nextSibling);
            rewardsMobile.remove();
        }
    }
}

const createClone = (elementToClone, classToRemove, classToAdd) => {
    const clone = elementToClone.cloneNode(true);
    classToRemove ? clone.classList.remove(classToRemove) : null;
    classToAdd ? clone.classList.add(classToAdd) : null;
    return clone;
}

if(document.readyState !== 'loading') {
    getWindowWidth();
} else {
    window.addEventListener('DOMContentLoaded', (event) => {
        getWindowWidth();
    });
}

window.addEventListener("resize", getWindowWidth);