import $ from "jquery";

$(document).ready(function() {
// Check if accordion element present, if present only run functions.
  if (document.querySelector("#nfl-accordion") != null) {
    
    // Check if collapse is present, if so hide it on start.
    if (document.querySelector("#nfl-accordion-collapse") != null) {
      const collapseAcc = document.querySelector("#nfl-accordion-collapse");
      $(collapseAcc).parents('.text').hide();
    }
    
    //Add event listeners for all expand buttons
    const expandBtns = document.querySelectorAll("#nfl-accordion-expand");
    const collapseBtns = document.querySelectorAll("#nfl-accordion-collapse");
    
    for (let i = 0; i < expandBtns.length; i++) {
      expandBtns[i].addEventListener("click", (e) => {
        e.preventDefault();

        $(expandBtns).parents('.text').hide();
        $(collapseBtns).parents('.text').show();

        $(e.currentTarget)
          .parents("#nfl-accordion")
          .find(".cmp-accordion__panel")
          .addClass("cmp-accordion__panel--expanded")
          .removeClass("cmp-accordion__panel--hidden")
          .attr("aria-hidden", false);

        $(e.currentTarget)
          .parents("#nfl-accordion")
          .find(".cmp-accordion__item")
          .attr("data-cmp-expanded", "");
          
          $(e.currentTarget)
          .parents("#nfl-accordion")
          .find(".cmp-accordion__button")
          .addClass("cmp-accordion__button--expanded")
          .attr("aria-expanded", true);
        });
      }
      
      // Add event listeners for all collapse buttons
      for (let i = 0; i < collapseBtns.length; i++) {
        collapseBtns[i].addEventListener("click", (e) => {
          e.preventDefault();

          $(collapseBtns).parents('.text').hide();
          $(expandBtns).parents('.text').show();

          $(e.currentTarget)
          .parents("#nfl-accordion")
          .find(".cmp-accordion__panel")
          .addClass("cmp-accordion__panel--hidden")
          .removeClass("cmp-accordion__panel--expanded")
          .attr('aria-hidden', true);
          
          $(e.currentTarget)
          .parents("#nfl-accordion")
          .find(".cmp-accordion__item")
          .removeAttr("data-cmp-expanded");
          
          $(e.currentTarget)
          .parents("#nfl-accordion")
          .find(".cmp-accordion__button")
          .removeClass("cmp-accordion__button--expanded")
          .attr("aria-expanded", false);
      });
    }

    // Add event listener to all accordion titles
    const acc = document.querySelectorAll(".cmp-accordion__header");
    for (let i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", (e) => {
        if ($(e.currentTarget).siblings('.cmp-accordion__panel').attr('aria-hidden') === 'false') {
          var $panel = $(e.currentTarget);
          $("html,body").animate(
            {
              scrollTop: $panel.offset().top,
            },
            500
          );
        }
      });
    }

    // Replacing # from javascript:void(0) in Expand and Collapse buttons
    const expandCollapseBtns = $('.nfl-accordion--text');
    $(expandCollapseBtns).each(function(){
      $(this).find('a').attr('href', 'javascript:void(0)');
    })
  }
});