const jquery = require("jquery")
window.$ = window.jQuery = jquery;
require("jquery-ui-dist/jquery-ui.css");
require("jquery-ui-dist/jquery-ui.js");

//EDS base configuration setup
var edsInit = function (setupConfig) {
    if (setupConfig) {
        window.BreadPayments.setup(setupConfig);
    }
}

//Pre-screen flow
var initPreScreenSubmitRtps = function(preScreenConfig) {
    if (preScreenConfig) {
        window.BreadPayments.submitRtps(preScreenConfig);
    }
}

//Update apply now element with default configuration options
var generatePlacements = function (isPlacementUpdated, placementConfigs) {
    if (!isPlacementUpdated && placementConfigs) {
        window.BreadPayments.registerPlacements(placementConfigs);
    }
}

$(document).ready(() => {
    if (typeof setupConfig !== 'undefined') { //EDS configuration will be updated only if the default config object is available in DOM
        edsInit(setupConfig);
    }
    const preScreenUrl = new URL(window.location);
    // if preScreenConfig is set/present
    if (typeof preScreenConfig !== "undefined") {
        if (preScreenUrl.pathname == '/cc/accept') {
            if (preScreenUrl.search.indexOf('prescreenId') > -1) {
                initPreScreenSubmitRtps(preScreenConfig)
            } else {
                preScreenConfig['customerAcceptedOffer'] = true;
                initPreScreenSubmitRtps(preScreenConfig)
            }
        } else {
            // if prescreenId is present
            if (preScreenUrl.search.indexOf('prescreenId') > -1) {
                // & if accept=true is present, set customerAcceptedOffer=true
                if (preScreenUrl.search.indexOf('accept=true') > -1) {
                    preScreenConfig['customerAcceptedOffer'] = true;
                    initPreScreenSubmitRtps(preScreenConfig)
                } 
                // & if accept=false is present, don't set customerAcceptedOffer
                else if (preScreenUrl.search.indexOf('accept=false') > -1) {
                    initPreScreenSubmitRtps(preScreenConfig)
                }        
            } 
            // if prescreenId is not present
            else {
                // & if accept=true is present, set customerAcceptedOffer=true
                if (preScreenUrl.search.indexOf('accept=true') > -1) {
                    preScreenConfig['customerAcceptedOffer'] = true;
                    initPreScreenSubmitRtps(preScreenConfig)
                } 
                // & if accept=false is present, don't set customerAcceptedOffer
                else if (preScreenUrl.search.indexOf('accept=false') > -1) {
                    initPreScreenSubmitRtps(preScreenConfig)
                } 
            }
        }
    }

    // Check if typeahead input element present, if present only run functions.
    if (document.querySelector(".js-typeahead") !== null) {
        let imageData;
        let teamsData;
        let isPlacementUpdated;
        const imageUrl = document.querySelector('.nfl-team-picker__typeahead').getAttribute('data-imageUrl');
        const data = document.querySelector('.nfl-team-picker__typeahead').getAttribute('data-mastercardclub');
        const masterCardData = {};
        if(data){
            const result = data.slice(1, data.length - 1).split(', ');
            for (const el of result) {
                const teamData = el.split('=');
                masterCardData[teamData[0]] = teamData[1];
            }
        }
        $(".nfl-team-picker__typeahead__wrapper--input").attr("disabled", true);
        fetch(imageUrl)
            .then((res) => res.json())
            .then((data) => (imageData = data))
            .then(() =>
                fetch('/content/nfl-extrapoints/us/en.teampicker.json')
                    .then((res) => res.json())
                    .then((data) => (teamsData = data))
                    .then(() => {
                        const autoCompleteData = [];

                        // Merging 2 API and making main data logic
                        teamsData.teams.forEach((team) => {
                            Object.values(imageData[':items']).forEach((img) => {
                                if (team.teamId === img.id) {
                                    const temp = {
                                        ...team,
                                        src: img.src,
                                        srcset: img.srcset,
                                        alt: img.alt
                                    };
                                    autoCompleteData.push(temp);
                                }
                            });
                        });
                        $(".nfl-team-picker__typeahead__wrapper--input").attr("disabled", false);

                        const noResultsText = 'No results found, try again.';
                        let selectedLabel = '';

                        $('.nfl-team-picker__typeahead__wrapper--input').autocomplete({
                            source: autoCompleteData,
                            minLength: 0,
                            response: function (event, ui) {
                                if (!ui.content.length) {
                                    var noResult = { label: noResultsText};
                                    ui.content.push(noResult);
                                }
                            },
                            close: function (event, ui) {
                                $('.nfl-team-picker__typeahead__wrapper--input').val(selectedLabel);
                            },
                            select: function (event, ui) {
                                $('.js-typeahead.nfl-team-picker__typeahead__wrapper--input').attr({
                                    "data-club-id": ui.item.teamId,
                                    "data-club-name": ui.item.label,
                                    "data-eds-id": ui.item.edsId
                                });
                                if (placementConfigs && placementConfigs[0] && ui.item.edsId) {
                                    placementConfigs[0]['selectedCardKey'] = ui.item.edsId;
                                    window.BreadPayments.registerPlacements(placementConfigs); //do not remove the place update call
                                    isPlacementUpdated = true;
                                }
                                const selectedSearchTermId = ui.item.teamId;
                                for (var key in masterCardData) {
                                    if (selectedSearchTermId === key) {
                                        window.location.href =
                                            window.location.protocol + "//" +
                                            window.location.host +
                                            masterCardData[key] +
                                            '.html';
                                    }
                                }
                                const selectedSearchTerm = ui.item.label;
                                if (selectedSearchTerm !== noResultsText) {
                                    $('.nfl-home-hero__container #team-picker-apply-now .ep-text-placement').attr('data-action-type', 'SHOW_OVERLAY'); 
                                    $('.nfl-home-hero__container .nfl-team-picker__typeahead__wrapper--input').removeClass('error');
                                    $('.nfl-team-picker__image-wrapper img')
                                    .attr({
                                        src: ui.item.src,
                                        srcset: ui.item.srcset,
                                        alt: ui.item.alt
                                    });
                                    $('.nfl-team-picker__typeahead__wrapper--input').blur();
                                } else {
                                    $('.nfl-home-hero__container #team-picker-apply-now .ep-text-placement').attr('data-action-type', ''); 
                                    $('.nfl-team-picker__typeahead__wrapper--input').blur();
                                    return false;
                                }
                            }
                        }).focus(function () {
                            selectedLabel = $('.nfl-team-picker__typeahead__wrapper--input').val();
                            $('.nfl-team-picker__typeahead__wrapper--input').val('');
                            $(this).autocomplete("search", "");
                        }).autocomplete("instance")._renderItem = function( ul, item ) {
                            if (item.label === noResultsText) {
                                return $("<li>")
                                .append("<div style='color:#CA3929'>" + noResultsText + "</div>")
                                .appendTo(ul);
                            } else {
                                return $("<li>")
                                .append("<div>" + item.label + "</div>")
                                .appendTo(ul);
                            }
                          };
                    })
            );
        generatePlacements(isPlacementUpdated, placementConfigs);
    }

    //Trigger eds call if eds id is available, typically on all club-landing pages
    if (document.querySelector(".nfl-team-picker__typeahead") && !document.querySelector(".js-typeahead")) {
        const clubLandingEdsId = document.querySelector('.nfl-team-picker__typeahead').getAttribute('data-club-landing-edsid');
        let isPlacementUpdated;
        if (clubLandingEdsId) {
            placementConfigs[0]['selectedCardKey'] = clubLandingEdsId;
            window.BreadPayments.registerPlacements(placementConfigs); //do not remove the place update call
            isPlacementUpdated = true;
        }
        //Update apply now element with default configuration options
        generatePlacements(isPlacementUpdated, placementConfigs);
    }

    //Removing attribute to stop modal
    const teampickerInput = $('.nfl-home-hero__container .nfl-team-picker__typeahead__wrapper--input');
    
    //apply interval to check if the apply now button is present & then remove interval
    var checkExist = setInterval(function() {
        if ($('.nfl-home-hero__container #team-picker-apply-now .ep-text-placement').length) {
           $('.nfl-home-hero__container #team-picker-apply-now .ep-text-placement').attr('data-action-type', '');
           clearInterval(checkExist);
        }
     }, 500);


    //Error on Autocomplete
    $(document).on('click', '.nfl-home-hero__container .nfl-team-picker__typeahead__apply-now', function(){
        const teampickerValue = $(teampickerInput).val().length;
        if(teampickerValue === 0){
            $(teampickerInput).addClass('error');
        }
        else{
            $(teampickerInput).removeClass('error');
        }
    });
});