import $ from 'jquery';
window.adobeDataLayer = window.adobeDataLayer || [];

var inputfield = document.querySelector('.js-typeahead.nfl-team-picker__typeahead__wrapper--input');
var applynowbutton = document.querySelector('.nfl-team-picker__typeahead__apply-now');

if(inputfield !== null) {
    inputfield.addEventListener('blur',function logEventObject(event) {
         var componentkey = $(event.srcElement).parent().parent().attr('id');
         window.adobeDataLayer.push({
             "component":{
                 [componentkey] : {
                     "teamSelectedName":$('.nfl-team-picker__typeahead__wrapper--input').attr('data-club-name'),
                     "teamSelectedId": $('.nfl-team-picker__typeahead__wrapper--input').attr('data-club-id'),
                     "typeaheadUsed": ($('.nfl-team-picker__typeahead__wrapper--input').val() !== '') ? true :false,
                     "typeaheadText":$('.nfl-team-picker__typeahead__wrapper--input').val(),
                     "referrerLink" : document.referrer
                 }
             },
			 "event" : "teamCardSearched",
			 "eventInfo" : {
				 "path" : "component." + [componentkey]
			 }
         });
    });
}
if(applynowbutton !== null){
    applynowbutton.addEventListener('click', function logClickObject(event) {
        var componentkey = $(event.srcElement).parent().parent().attr('id');
        if($('.nfl-team-picker__typeahead__wrapper--input').val() !== '') {
            window.adobeDataLayer.push({
                "component":{
                    [componentkey] : {
                        "applyNowclick":true,
						"teamSelectedName":$('.nfl-team-picker__typeahead__wrapper--input').attr('data-club-name'),
						"teamSelectedId": $('.nfl-team-picker__typeahead__wrapper--input').attr('data-club-id')
                     }
                },
				"event" : "teamCardApply",
				"eventInfo" : {
					"path" : "component." + [componentkey]
				}
            });
        }
		if($('.nfl-team-picker__typeahead__wrapper--input').val() === '') {
            window.adobeDataLayer.push({
				"error":{
					"errorMessage": "No team selected"
				},
				"event":"interactionError",
				"eventInfo" : {
					"path" : "error"
				}
            });
        }
    });
}