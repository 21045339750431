import $ from "jquery";

$(document).ready(function() {
    if(document.querySelector(".bf-modal")!=null) {
        MicroModal.init({disableScroll: true, disableFocus: true});
        $('body').find('a').each(function() {
            const href=$(this).attr('data-micromodal-trigger');
            if (href) {
                $(this).attr('href', 'javascript:void(0)');
            }
        });
    }
});
