const getWindowWidth = () => {
    addMobileRewards(document.documentElement.clientWidth);
}

const addMobileRewards = (screenWidth) => {
    if(screenWidth < 768) {
        const rewards = document.querySelector('.nfl-home-hero__rewards');
        const moreDetails = document.querySelector('.nfl-home-hero__container .nfl-home-hero__more-details');
        if(rewards) {
            const newClone = createClone(rewards, 'nfl-home-hero__rewards', 'nfl-home-hero__rewards-mobile__container');
            const mobileRewardBlock = document.querySelector('.nfl-home-hero__rewards-mobile .cmp-container .aem-Grid'); 
            rewards.remove();
            if(mobileRewardBlock) {
                mobileRewardBlock.prepend(newClone);
            }
        }
        if(moreDetails) {
            const newClone = createClone(moreDetails, null, 'nfl-home-hero__rewards-mobile__more');
            const mobileMoreDetailsBlock = document.querySelector('.nfl-home-hero__rewards-mobile .cmp-container .aem-Grid');
            moreDetails.remove();
            if(mobileMoreDetailsBlock) {
                mobileMoreDetailsBlock.append(newClone);
                MicroModal.init();
            }
        }
    }
    else {
        const rewardsMobile = document.querySelector('.nfl-home-hero__rewards-mobile__container');
        const moreDetailsMobile = document.querySelector('.nfl-home-hero__rewards-mobile__more');
        if(rewardsMobile){
            const newClone = createClone(rewardsMobile, 'nfl-home-hero__rewards-mobile__container', 'nfl-home-hero__rewards');
            const nflHomeTitle = document.querySelector('.nfl-hero--subtitle');
            nflHomeTitle.parentNode.insertBefore(newClone, nflHomeTitle.nextSibling);
            rewardsMobile.remove();
        }
        if(moreDetailsMobile) {
            const newClone = createClone(moreDetailsMobile, '.nfl-home-hero__rewards-mobile__more', null);
            document.querySelector('.nfl-home-hero__container .cmp-container .aem-Grid').append(newClone);
            moreDetailsMobile.remove();
            MicroModal.init();
        }
    }
}

const createClone = (elementToClone, classToRemove, classToAdd) => {
    const clone = elementToClone.cloneNode(true);
    classToRemove ? clone.classList.remove(classToRemove) : null;
    classToAdd ? clone.classList.add(classToAdd) : null;
    return clone;
}

if(document.readyState !== 'loading') {
    getWindowWidth();
} else {
    window.addEventListener('DOMContentLoaded', (event) => {
        getWindowWidth();
    });
}

window.addEventListener("resize", getWindowWidth);