import $ from 'jquery';

$(document).ready(function () {
    // Check if sticky element present, if present only run functions.
    if (document.querySelector(".nfl-sticky-wrapper") !== null) {
        $(window).scroll(function () {
            const sticky = $('.nfl-sticky-wrapper > div.cmp-container');
            const stickyBanner = $(".nfl-sticky-inner-wrapper > div.cmp-container").height();
            const floodLights = $(".nfl-sticky-bg-image").height();
            const homeBanner = $(".nfl-home-hero__container").height();
            const landingBanner = $(".nfl-club-hero__container").height();
            const scrollHeight = homeBanner + $(".nfl-header").height() || landingBanner + $(".nfl-header").height();
            const footerHeight = $(".nfl-footer").height();
            if ($(window).scrollTop() > scrollHeight) {
                sticky.show();
                sticky.addClass('sticky-fixed');
                if (footerHeight) {
                    $('.nfl-footer').css('paddingBottom', stickyBanner+floodLights+10);
                }
            } else {
                sticky.hide();
                sticky.removeClass('sticky-fixed');
            }
        });

        $(".nfl-sticky-button-wrapper a").click(function() {
            $("html").animate({ scrollTop: 0 }, "slow");
        });
    }
});
