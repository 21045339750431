import $ from 'jquery';

$(document).ready(function() {
  // Check if carousel element present, if present only run functions.
  if (document.querySelector(".nfl-carousel") !== null) {
    $(".nfl-carousel > div").slick({
      slidesToShow: 4,
      slidesToScroll: 4,
      dots: false,
      infinite: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    });
  }
});